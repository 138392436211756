import { useRef, useState } from "react";

const ColorPicker = () => {
  const [color, setColor] = useState('#FFFFFF')
  const myCanvas = useRef()

  const handleImage = (e) => {
    const canvas = myCanvas.current;
    const ctx = canvas.getContext('2d')

    var reader = new FileReader()
    reader.onload = function (event) {
      var img = new Image()
      img.onload = function () {
        canvas.width = img.width
        canvas.height = img.height
        ctx.drawImage(img, 0, 0)
      }
      img.src = event.target.result
    }
    reader.readAsDataURL(e.target.files[0])
  }

  const rgbToHex=(r, g, b)=> {
    if (r < 256 || g < 256 || b < 256)
      return ((r << 16) | (g << 8) | b).toString(16)
  }

  const handlePickColor = (e) => {
    const canvas = myCanvas.current;
    const ctx = canvas.getContext('2d')

    const rect = canvas.getBoundingClientRect()
    const x = e.clientX - rect.left
    const y = e.clientY - rect.top
    
    var p = ctx.getImageData(x, y, 1, 1).data;
    var hex = "#" + ("000000" + rgbToHex(p[0], p[1], p[2])).slice(-6)
    setColor(hex);
    console.log("x: " + x + " y: " + y, p)
  }

  return (
    <>
      <h1>Color Picker</h1>
      <div className="mb-3 d-flex">
        <input type="file" className="form-control me-3" onChange={(e) => { handleImage(e) }} />
        <input type="text" className="form-control me-3" value={color} readOnly />
        <div className="px-5 py-2" style={{backgroundColor: color} }></div>
        
      </div>
      <div className="overflow-auto">
        <canvas ref={myCanvas} onClick={(e) => handlePickColor(e)}></canvas>
      </div>
    </>
  )
}
export default ColorPicker