import { Route, Routes } from 'react-router-dom'
import Layout from './app/components/shared/layout'
import Container from './app/components/shared/container'
import Home from './app/components/home'
import Landingpages from './app/pages/landingpages'
import Boilerplates from './app/pages/boilerplates'
import IndexHtml from './app/pages/boilerplates/indexhtml'
import CodeFormatter from './app/pages/codeformatter'
import PWAIndexHtml from './app/pages/boilerplates/pwaindexhtml'
import ExpressServer from './app/pages/boilerplates/expressserver'
import Colors from './app/pages/colors'
import GradientColors from './app/pages/gradientcolors'
import ColorPicker from './app/pages/colorpicker'
import Canvas from './app/pages/canvas'

function App() {
  return (
    <Routes>
      <Route element={<Container />}>
        <Route path="*" component={<Home />} status={404} />
        <Route path="/landing-pages" element={<Landingpages />} />
        <Route path="/boilerplates" element={<Boilerplates />} />
        <Route path="/boilerplates/indexhtml" element={<IndexHtml />} />
        <Route path="/boilerplates/pwaindexhtml" element={<PWAIndexHtml />} />
        <Route path="/boilerplates/pwaindexhtml" element={<PWAIndexHtml />} />
        <Route path="/boilerplates/express-server" element={<ExpressServer />} />
        <Route path="/code-formatter" element={<CodeFormatter />} />
        <Route path="/colors" element={<Colors />} />
        <Route path="/gradient-colors" element={<GradientColors />} />
        <Route path="/color-picker" element={<ColorPicker />} />
        <Route path="/canvas" element={<Canvas />} />
      </Route>
      <Route element={<Layout />}>
        <Route path="/" element={<Home />} />
      </Route>
    </Routes>
  )
}

export default App
