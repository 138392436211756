const GradientColors = () => {
  const gradients = [['#492f4e', '#9974b5'], ['#5985c1', '#86b2ee'], ['#e6ca0b', '#ffed2e'], ['#6c993e', '#a3d075'], ['#af0000', '#dc2d2d'], ['#46747c', '#8cbac2']]
  return (
    <>
      <h1>Gradient Colors</h1>
      <div className="d-grid grid-container">
        {
          gradients.map((item, i) => (
            <div key={`gradient-${i}` } className="p-5 rounded text-white text-monospace" style={{ background: `linear-gradient(90deg, ${item[0]} 50%, ${item[1]} 100%)` }}>{`${item[0]} - ${item[1]}`}</div>
          ))
        }
      </div>
    </>
  )
}
export default GradientColors