import { Link } from 'react-router-dom'

const Boilerplates = () => {
  return (
    <>
      <h1>Boilerplates</h1>
      <div className="d-flex">
        <div className="p-3 border-end">
          <ul className="list-unstyled">
            <li>
              <h6 className="fw-bold">HTML</h6>
              <ul className="list-unstyled ps-3">
                <li><Link className="nav-link fs-6" to={"indexhtml"}>Standard index.html</Link></li>
                <li><Link className="nav-link fs-6" to={"pwaindexhtml"}>Progressive Web App</Link></li>
              </ul>
            </li>
            <li>
              <h6 className="fw-bold mt-3">React JS</h6>
              <ul className="list-unstyled ps-3 fs-6">
                <li><Link className="nav-link fs-6">React Basic Component</Link></li>
              </ul>
            </li>
            <li>
              <h6 className="fw-bold mt-3">Node JS</h6>
              <ul className="list-unstyled ps-3 fs-6">
                <li><Link className="nav-link fs-6" to={'express-server'}>Express Server</Link></li>
              </ul>
            </li>
            <li>
              <h6 className="fw-bold mt-3">Tools</h6>
              <ul className="list-unstyled ps-3 fs-6">
                <li><Link className="nav-link fs-6" to={'../code-formatter' }>Code Formatter</Link></li>
              </ul>
            </li>
          </ul>
        </div>
        <div className="flex-grow-1 p-3">
          Content
        </div>
      </div>
    </>
  )
}
export default Boilerplates