import { useState } from "react"

const CodeFormatter = () => {
  const [code, setCode] = useState('');
  const [inputCode, setInputCode] = useState('');

  const format = () => {
    setCode(String(inputCode).replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;'))
  }

  return (
    <>
      <h1>Code Formatter</h1>
      <textarea onChange={(e) => { setInputCode(e.target.value) }} className="w-100" style={{minHeight:'20rem'} }></textarea>
      <div className="my-3">
        <button className="btn btn-primary rounded-0" onClick={() => format() }>Format</button>
      </div>
      <div>
        <pre className="border text-bg-dark p-3" style={{maxHeight:'30rem'} }>
          <code>
            { code }
          </code>
        </pre>
      </div>
    </>
  )
}
export default CodeFormatter