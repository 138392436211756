import { Outlet } from 'react-router-dom'
import Header from '../shared/header'
import Footer from '../shared/footer'

const Container = ({ children }) => {
  return (
    <>
      <Header />
      <div className="container-fluid my-2">
        <Outlet />
      </div>
      <Footer />
    </>
  )
}

export default Container
