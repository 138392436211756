import { useState } from "react"

const Colors = () => {
  const colors = ['#7F7F7F', '#FF0800', '#19A519', '#0068a5', '#A43BE9', '#8697C4', '#3D5A80', '#808000', '#3D52A0', '#7091E6', '#936639', '#7DABB3', '#ED7845', '#EFB11D', '#87AA4D']
  const repeat = [-90,-80,-70,-60,-50,-40,-30,-20,-10,0,10,20,30,40,50,60,70,80,90]
  const lighten = [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95]
  const darken = [-5, -10, -15, -20, -25, -30, -35, -40, -45, -50, -55, -60, -65, -70, -75, -80, -85, -90, -95]
  const [color, setColor] = useState('#739639');
  const [light, setLight] = useState([]);
  const [dark, setDark] = useState([]);

  const hexToRGB = (hex) => {
    var bigint = parseInt(hex, 16);
    var r = (bigint >> 16) & 255;
    var g = (bigint >> 8) & 255;
    var b = bigint & 255;

    return [r, g, b]
  }

  const shadeColor = (col, amt) => {
    col = col.replace(/^#/, '')
    if (col.length === 3) col = col[0] + col[0] + col[1] + col[1] + col[2] + col[2]

    let [r, g, b] = col.match(/.{2}/g);
    ([r, g, b] = [parseInt(r, 16) + amt, parseInt(g, 16) + amt, parseInt(b, 16) + amt])

    r = Math.max(Math.min(255, r), 0).toString(16)
    g = Math.max(Math.min(255, g), 0).toString(16)
    b = Math.max(Math.min(255, b), 0).toString(16)

    const rr = (r.length < 2 ? '0' : '') + r
    const gg = (g.length < 2 ? '0' : '') + g
    const bb = (b.length < 2 ? '0' : '') + b

    return `#${rr}${gg}${bb}`
  }
  
  const lightenColor = () => {
    setLight(lighten.map(number => shadeColor(color, number)));
  }
  const darkenColor = () => {
    setDark(darken.map(number => shadeColor(color, number)));
  }
  const blackOrWhite = (hex) => {
    const [r, g, b] = hexToRGB(hex)
    console.log('hex to RGB: ', hex, r, g, b)
    return (r * 0.299 + g * 0.587 + b * 0.114) > 128 ? '#fff' : '#000'
  }
  
  return (
    <>
      <h1>Background Colors</h1>
      <div className="d-flex my-4">
        <input type="text" className="form-control me-3" value={color} onChange={(e) => setColor(e.target.value)} style={{ maxWidth: '10rem' }} placeholder="#7091E6" />
        <input type="color" className="me-3" style={{ minHeight: '3rem', minWidth:'3rem' }} value={color} onChange={(e)=> setColor(e.target.value)} />
        <button className="btn btn-primary me-3" onClick={() => { lightenColor() }} disabled={color.trim().length<4 }>Lighten</button>
        <button className="btn btn-secondary" onClick={() => { darkenColor() }} disabled={color.trim().length < 4}>Darken</button>
      </div>
      <div className="d-flex font-monospace text-center overflow-auto">
        {
          light.map((k, index) => (
            <div key={`color-${index}`} className="p-1 py-5" style={{ backgroundColor: k, flex: '5%' }} onClick={(e) => setColor(e.target.innerText)} role="button">{k}</div>
          ))
        }
      </div>
      <div className="d-flex font-monospace text-center overflow-auto text-white">
        {
          dark.map((k, index) => (
            <div key={`color-${index}`} className="p-1 py-5" style={{ backgroundColor: k, flex: '5%' }} onClick={(e) => setColor(e.target.innerText)} role="button">{k}</div>
          ))
        }
      </div>
      <div className="font-monospace text-center">
        {colors.map((i, ind) => (
          <div className="my-3 d-flex overflow-auto" key={`colorgroup-${ind}` }>
            {
              repeat.map((j,index) => (
                <div key={`color-${index}`} className="p-1 py-5" style={{ backgroundColor: shadeColor(i, j), color: blackOrWhite(j), flex: '5%' }} onClick={(e) => setColor(e.target.innerText) } role="button">{shadeColor(i, j)}</div>
              ))
            }
          </div>
        ))}
      </div>
    </>

  )
}
export default Colors