import Nav from '../shared/nav'

const Header = () => {
    return (
        <header className="sticky-top">
            <div>
                <Nav/>
            </div>
        </header>
    )
}
export default Header
